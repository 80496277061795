import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import Logo from "@src/assets/images/configure-app/logo.svg";
import { Box, Flex } from "@src/components/Boxes";
import { getAttrSearchParams } from "@src/utils/attribution";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import { incrementMetric } from "@src/utils/logClient";

const AddKid: React.FC = () => {
  useRedirectHandler(() => {
    const parsedLocation = queryString.parse(window?.location?.search || "");
    const { target, schoolID, class: classID, code, email, hash } = parsedLocation;

    let redirectLink: string;

    if (target === "school" && schoolID) {
      redirectLink = `https://home.classdojo.com/#/classInvite/?s=${schoolID}&`;
    } else if (target === "school") {
      redirectLink = `https://home.classdojo.com/#/teacher-search?`;
    } else if (target === "class" && classID) {
      redirectLink = `https://home.classdojo.com/#/classInvite/?c=${classID}&`;
    } else if (target === "code" && code) {
      redirectLink = `https://home.classdojo.com/#/signup?invite=${code}&`;
    } else if (code) {
      redirectLink = `https://home.classdojo.com/#/signup?invite=${code}&`;
    } else {
      incrementMetric("external_site.universal_link.fallback", { component: "AddKid" });
      redirectLink = `https://home.classdojo.com/#/code?`;
    }
    const params = getAttrSearchParams();
    if (typeof email === "string") {
      params.append("emailAddress", email);
    }
    if (typeof hash === "string") {
      params.append("hash", hash);
    }

    redirectLink += params.toString();
    return redirectLink;
  });

  return (
    <>
      <SEO noindex={true} />
      <Flex justifyContent="center" alignItems="center" width="100%" height="calc(100vh - 88px)">
        <Box width="100px" minWidth="100px">
          <img src={Logo} alt="" />
        </Box>
      </Flex>
    </>
  );
};
export default AddKid;
